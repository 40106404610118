import React from 'react';

import './default-styles/style-bundle.css';
import IndexPage from './routes/home'

function App(){
  return <IndexPage />
}

export default App;
