import React, { useEffect, useState } from 'react';

import './style.css';

import { ReturnIcon } from '../icons';
import { availableServices } from '../../../services/available-services';

export default function Modal({ hideModal, data_id }) {

    const [ data, setData ] = useState({});

    useEffect(() => {
        const data = availableServices.filter( item => item._id === data_id )[0];
        setData(data);
    }, [])

    return(
        <div className="modal">
            <div className="modal__image"></div>
            <div className="modal__text-block">
                <button className="modal__back-button" onClick={hideModal}>
                    <ReturnIcon color="#B5B5B5" />
                </button>
                <h2 className="modal__title">{data.title}</h2>
                { data.text && data.text.map((item, index) => <p className="modal__text" key={index}>{item}</p>) }
            </div>
        </div>
    )

}
