import React from 'react';

import './services-section.css';

import imgPassport from './images/nationality__image.png';

function ServicesSection({ showModal }){
    return(
        <section className="services-section" id="services-section">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="services-section__title">Serviços</h2>
                    </div>
                </div>
            </div>
            <div className="service-section__portuguese-nationality">
                <div className="container">
                    <div className="row">
                        <div className="service-section__portuguese-nationality__image">
                            <img src={imgPassport} alt="Passaporte Português" />
                        </div>
                        <div className="col col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 offset-md-5 offset-lg-5 offset-xl-5">
                            <h3 className="service-section__portuguese-nationality__title">Nacionalidade portuguesa</h3>
                            <p className="service-section__portuguese-nationality__text">Existem diferentes formas de se obter a nacionalidade portuguesa, sendo mais comum a transmissão da nacionalidade dos cidadãos portugueses aos seus filhos nascidos fora do território português. Por tratar-se de um processo de atribuição da nacionalidade para filhos, não há necessidade de o cidadão português estar vivo.</p>
                            <button className="button primary-button" onClick={() => showModal(1)}>Quero saber mais</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <div className="service-list__service-item">
                            <h4 className="service-list__service-item__title">Vistos</h4>
                            <p className="service-list__service-item__text">Com foco no mercado português e acompanhando as recentes alterações no direito migratório, nossa equipe de consultores possui capacidade para prestar a assessoria para aqueles que pr...</p>
                            <button className="button primary-button service-list__service-item__button" onClick={() => showModal(2)}>Quero saber mais</button>
                        </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <div className="service-list__service-item">
                            <h4 className="service-list__service-item__title">Revalidação de Diploma</h4>
                            <p className="service-list__service-item__text">Um dos requisitos para a atuação no mercado europeu consiste na revalidação do grau acadêmico conferido pela Universidade no Brasil. Atualmente, o processo de revalidação de diplomas...</p>
                            <button className="button primary-button service-list__service-item__button" onClick={() => showModal(3)}>Quero saber mais</button>
                        </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <div className="service-list__service-item">
                            <h4 className="service-list__service-item__title">Imóveis em Portugal</h4>
                            <p className="service-list__service-item__text">Visando atender uma solicitação de alguns clientes, passamos a atuar no mercado imobiliário português, através de parcerias com as mais conceituadas agências...</p>
                            <button className="button primary-button service-list__service-item__button" onClick={() => showModal(4)}>Quero saber mais</button>
                        </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                        <div className="service-list__service-item">
                            <h4 className="service-list__service-item__title">Retificação de sentença estrangeira</h4>
                            <p className="service-list__service-item__text">De acordo com o Código de Registo Civil e do Regulamento da Nacionalidade Portuguesa, o cidadão português é obrigado a manter seu...</p>
                            <button className="button primary-button service-list__service-item__button" onClick={() => showModal(5)}>Quero saber mais</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSection;