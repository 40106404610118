import React, { useState } from 'react';
import axios from 'axios';

import ContactForm from '../contact-form';
import { ChatIcon, CrossIcon } from '../../common/icons';

import './contact-section.css';

function ContactSection({ theme, isVisible, toggleContactVisibility }){
    const [ name, fillName ] = useState('');
    const [ email, fillEmail ] = useState('');
    const [ subject, fillSubject ] = useState('');
    const [ message, fillMessage ] = useState('');
    const [ isLoading, setLoadingState ] = useState(false);
    const [ mailSent, setMailSentMessage ] = useState(false);
    const [ mailError, setMailErrorMessage ] = useState(false);
    const [ emptyName, checkName ] = useState(false);
    const [ emptyEmail, checkEmail ] = useState(false);
    const [ emptySubject, checkSubject ] = useState(false);
    const [ emptyMessage, checkMessage ] = useState(false);
    
    let formClasses = ['contact-form-section'];
    let backScreenClassNames = ['contact-back-screen']
    
    if( isVisible ){
        formClasses.push('contact-form--visible');
        backScreenClassNames.push('contact-back-screen--visible');
    }

    const buttonClasses = `contact-button contact-button--${theme}-theme`;

    const setName = event => {
        fillName(event.target.value)
    }

    const setEmail = event => {
        fillEmail(event.target.value)
    }

    const setSubject = event => {
        fillSubject(event.target.value)
    }

    const setMessage = event => {
        fillMessage(event.target.value)
    }

    const isInvalidForm = () => {
        let isInvalid = 0;

        if(!name){
            checkName(true);
            isInvalid++;
        } else {
            checkName(false)
        }

        if(!email){
            checkEmail(true);
            isInvalid++;
        } else if(email.indexOf('@') === -1){
            checkEmail(true);
            isInvalid++;
        } else {
            checkEmail(false)
        }

        if(!subject){
            checkSubject(true);
            isInvalid++;
        } else {
            checkSubject(false)
        }

        if(!message){
            checkMessage(true);
            isInvalid++;
        } else {
            checkMessage(false)
        }

        return isInvalid;
    }

    const submitEmail = async event => {
        event.preventDefault();

        if( isInvalidForm() ){
            return;
        } 

        setLoadingState(true);

        try {
            const response = await axios.post('/api/v1/enviar-email.php', {
                nome: name,
                email: email,
                assunto: subject,
                mensagem: message
            });
            
            const { mailSent } = response.data;

            setLoadingState(false);

            if(mailSent){
                setMailSentMessage(true);    
            } else {
                setMailErrorMessage(true);
            }
        } catch(err){
            setLoadingState(false);
            setMailErrorMessage(true);
        }
    }

    return(
        <>
            <div className={buttonClasses} onClick={toggleContactVisibility}>
                { !isVisible && <ChatIcon theme={theme} /> }
                { isVisible && <CrossIcon theme={theme} /> }
            </div>

            <div className={backScreenClassNames.join(' ')}></div>

            <div className={formClasses.join(' ')}>
                <h3 className="contact-form__title">Fale conosco</h3>
                <p className="contact-from__text">Estamos à disposição para qualquer esclarecimento que se faça necessário.</p>

                <form onSubmit={submitEmail}>
                    <ContactForm
                        isLoading={isLoading}
                        name={name}
                        setName={setName}
                        email={email}
                        setEmail={setEmail}
                        subject={subject}
                        setSubject={setSubject}
                        message={message}
                        setMessage={setMessage}
                        mailSent={mailSent}
                        mailError={mailError}
                        emptyName={emptyName}
                        emptyEmail={emptyEmail}
                        emptySubject={emptySubject}
                        emptyMessage={emptyMessage}
                    />
                </form>
            </div>
        </>
    )

}

export default ContactSection;