import React from 'react';
import { FAQ } from '../../../services/faq-service';

import './faq-section.css';

function FAQSection(){
    return(
        <section className="faq-section" id="faq-section">
            <div className="container">
                <div className="row row-centered">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="faq-section__title">Perguntas frequentes</h2>
                        <ol className="faq-list">
                            { 
                                FAQ.map((item, index) => (
                                    <li key={index} className="faq-list__item">
                                        <p className="faq-list__item__question">{item.question}</p>
                                        <p className="faq-list__item__answer">{item.answer}</p>
                                    </li>
                                ))
                            }
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQSection;