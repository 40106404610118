import React from 'react';

import { Loading, SuccessMessage, ErrorMessage } from '../../common/async-components';
import { TextInput, TextArea } from '../../common/inputs';

import './styles.css';

function ContactForm(props){
    const {
        isLoading,
        name,
        setName,
        email,
        setEmail,
        subject,
        setSubject,
        message,
        setMessage,
        mailSent,
        mailError,
        emptyName,
        emptyEmail,
        emptySubject,
        emptyMessage,
    } = props;

    if(isLoading){
        return <Loading />
    }

    if(mailSent){
        return <SuccessMessage />
    }

    if(mailError){
        return <ErrorMessage />
    }

    return (
        <>
            <TextInput
                id="inpt-name"
                label="Nome"
                type="text"
                placeholder="Digite seu nome"
                value={name}
                onChange={setName}
            />

            { emptyName && <p className="empty-field">Por favor preencha o campo Nome</p> }

            <TextInput
                id="inpt-email"
                label="Email"
                type="email"
                placeholder="Digite seu email"
                value={email}
                onChange={setEmail}
            />

            { emptyEmail && <p className="empty-field">Por favor preencha o campo Email</p> }

            <TextInput
                id="inpt-subject"
                label="Assunto"
                type="text"
                placeholder="Digite seu assunto"
                value={subject}
                onChange={setSubject}
            />

            { emptySubject && <p className="empty-field">Por favor preencha o campo Assunto</p> }

            <TextArea
                id="inpt-message"
                label="Mensagem"
                placeholder="Digite sua mensagem aqui"
                value={message}
                onChange={setMessage}
            />

            { emptyMessage && <p className="empty-field">Por favor preencha o campo Mensagem</p> }

            <button className="button primary-button">Enviar mensagem</button>
        </>
    )
}

export default ContactForm;