export const availableServices = [
    {
        _id: 1,
        title: "Nacionalidade portuguesa",
        text: [
            "Existem diferentes formas de se obter a nacionalidade portuguesa, sendo mais comum a transmissão da nacionalidade dos cidadãos portugueses aos seus filhos nascidos fora do território português. Por tratar-se de um processo de atribuição da nacionalidade para filhos, não há necessidade de o cidadão português estar vivo.",
            "Tratando-se de neto de portugueses – ou seja, quando o filho direto do português não é falecido -, o descendente em segundo grau também poderá obter a nacionalidade portuguesa, desde que comprove que possui laços com a comunidade portuguesa (ex.: viagens frequentes à Portugal; a efetiva participação em eventos culturais em associações portuguesas no país onde o requerente resida, etc.)."
        ],
        image: "service__nationality"
    },
    {
        _id: 2,
        title: "Vistos",
        text: [
            "Com foco no mercado português e acompanhando as recentes alterações no direito migratório, nossa equipe de consultores possui capacidade para prestar a assessoria para aqueles que pretendem residir legalmente em Portugal.",
            "Atualmente, de acordo com a legislação portuguesa, o cidadão estrangeiro, pode vir a obter três tipos de visto de residência em Portugal: (i) visto gold – destinado a cidadãos estrangeiros que pretendem investir em Portugal; (ii) visto de aposentados/rendimentos próprios – destinado àqueles cidadãos que possuem rendimentos fixos (ex.: aposentados); visto de empreendedor – voltado para aqueles que pretendem constituir uma sociedade em Portugal."
        ]
    },
    {
        _id: 3,
        title: "Revalidação de diplomas",
        text: [
            "Um dos requisitos para a atuação no mercado europeu consiste na revalidação do grau acadêmico conferido pela Universidade no Brasil. Atualmente, o processo de revalidação de diplomas é conferido pelo Decreto-Lei n.º 66/2018, em vigor desde o dia 1º de Janeiro de 2019.",
            "Tendo em vista que o Decreto-Lei possui uma finalidade geral e abstrata, o procedimento de revalidação de diplomas é aplicado para o reconhecimento de todos os cursos.",
            "Entretanto, esse processo não impede a realização de acordos entre faculdades portuguesas e brasileiras, tal como aquele celebrado entre a Faculdade de Medicina da Universidade do Rio de Janeiro (UFRJ) e a Faculdade de Medicina da Universidade de Lisboa (ULisboa).",
            "Para maiores informações sobre o processo de revalidação de diplomas, entre em contato que nossa equipe de especialistas poderá prestar todo o suporte necessário."
        ]
    },
    {
        _id: 4,
        title: "Imóveis em Portugal",
        text: [
            "Visando atender uma solicitação de alguns clientes, passamos a atuar no mercado imobiliário português, através de parcerias com as mais conceituadas agências imobiliárias daquele país.",
            "Nossa equipe realiza toda a análise jurídica inerente à compra e venda de imóveis, bem como no arrendamento de propriedades."
        ]
    },
    {
        _id: 5,
        title: "Retificação de sentença estrangeira",
        text: [
            "De acordo com o Código de Registo Civil e do Regulamento da Nacionalidade Portuguesa, o cidadão português é obrigado a manter seu estado civil devidamente atualizado em Portugal. Assim, todos os atos da vida civil devem ser devidamente comunicados às repartições competentes.",
            "No entanto, nem toda alteração pode ser feita através das Conservatórias do Registo Civil, uma vez que decisões emitidas por Tribunais devem, obrigatoriamente, serem revistas e reconhecidas pelo Tribunal da Relação em Portugal.",
            // "Em processo de nacionalidade, as sentenças mais reconhecidas são as de divórcio e adoção.  Somente após o reconhecimento da sentença na ordem jurídica portuguesa, pelo Tribunal competente, será possível prosseguir com eventual processo de nacionalidade."
        ]
    }
]