import React from 'react';

import FooterLogo from '../../common/footer-logo';
import { FacebookLogo, InstagramLogo, FantasticaIcon } from '../../common/icons';

import './footer.css';

function Footer(){
    return (
        <footer className="main-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div className="main-footer__logo-container">
                            <FooterLogo theme="#777777" />
                        </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <div className="main-footer__contact">
                            <h4 className="main-footer__location">Brasil</h4>
                            <span className="main-footer__address">Rua México, n.º 31, Sala 703, Centro, <br />Rio de Janeiro, CEP 20031-141</span>
                            <span className="main-footer__phone">(21) 3900-8967</span>
                        </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <div className="main-footer__contact">
                        <h4 className="main-footer__location">Portugal</h4>
                            <span className="main-footer__address">Rua João do Ruão, Centro Comercial Sofia, Sala 34, <br/>Coimbra</span>
                            <span className="main-footer__phone">+35 1937677975</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12">
                        <div className="main-footer__bottom-section">
                            <div className="main-footer__copyright">
                                <span>&copy; 2019 Daniel Monteiro</span>
                            </div>
                            <div className="main-footer__social-links">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/pages/category/Lawyer---Law-Firm/Daniel-Monteiro-Assessoria-jur%C3%ADdica-316395772489090/" target="_blank" rel="noopener noreferrer">
                                            <FacebookLogo color="#777777" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/assessoriadm/" target="_blank" rel="noopener noreferrer">
                                            <InstagramLogo color="#777777" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="main-footer__dev">
                                <a href="http://www.fantasticafabricadesites.com.br/" target="_blank" rel="noopener noreferrer">
                                    <FantasticaIcon theme="light"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;