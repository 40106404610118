import React from 'react';
import { Link } from "react-scroll";

import './style.css';

export default function MobileMenu({ theme, toggleMenuState }){
    return (
        <nav className={`mobile-menu ${theme}-theme`}>
            <ul>
                <Link activeClass="active" to="about-section" spy={true} smooth={true} duration= {400}>
                    <li className="mobile-menu__item" onClick={toggleMenuState}>Sobre</li>
                </Link>
                <Link activeClass="active" to="services-section" spy={true} smooth={true} duration= {400}>
                    <li className="mobile-menu__item" onClick={toggleMenuState}>Serviços</li>
                </Link>
                <Link activeClass="active" to="info-section" spy={true} smooth={true} duration= {400}>
                    <li className="mobile-menu__item" onClick={toggleMenuState}>Informativos</li>
                </Link>
                <Link activeClass="active" to="faq-section" spy={true} smooth={true} duration= {400}>
                    <li className="mobile-menu__item" onClick={toggleMenuState}>Perguntas Frequentes</li>
                </Link>
            </ul>
        </nav>
    )
}