import React, { useState } from 'react';
import { Link } from "react-scroll";

import MainLogo from '../../common/main-logo';
import { HamburgerIcon } from '../../common/icons';
import MobileMenu from '../mobile-menu';

import './main-header.css';

function MainHeader({ theme }) {
    const [ menuIsOpen, toggleMenu ] = useState(false);

    const toggleMenuState = () => {
        toggleMenu( !menuIsOpen );
    }

    return (
        <>
            <header className={`main-header main-header--${theme}`}>
                <div className="container">
                    <nav className="main-navigation">
                        <ul>
                            <li className="main-navigation__item">
                                <Link
                                    activeClass="active"
                                    to="about-section"
                                    spy={true}
                                    smooth={true}
                                    duration= {400}
                                >Sobre</Link>
                            </li>
                            <li className="main-navigation__item">
                                <Link
                                    activeClass="active"
                                    to="services-section"
                                    spy={true}
                                    smooth={true}
                                    duration= {400}
                                >Serviços</Link>
                            </li>
                            <li className="logo-container"><MainLogo theme={theme} /></li>
                            <li className="main-navigation__item">
                                <Link
                                    activeClass="active"
                                    to="info-section"
                                    spy={true}
                                    smooth={true}
                                    duration= {400}
                                >Informativos</Link>
                            </li>
                            <li className="main-navigation__item">
                                <Link
                                    activeClass="active"
                                    to="faq-section"
                                    spy={true}
                                    smooth={true}
                                    duration= {400}
                                >Perguntas Frequentes</Link>
                            </li>
                            <li className="main-navigation__menu-button">
                                <HamburgerIcon
                                    theme={theme}
                                    isMenuOpen={menuIsOpen}
                                    onClick={toggleMenuState}
                                />
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            { menuIsOpen && <MobileMenu theme={theme} toggleMenuState={toggleMenuState} /> }
        </>
    )
}

export default MainHeader;