export const FAQ = [
    {
        question: "Quais os benefícios em obter uma cidadania portuguesa?",
        answer: "Além de estreitar ainda mais os laços com os ascendentes portugueses que migraram há muitos anos, o reconhecimento da nacionalidade portuguesa não produz efeitos apenas em território lusitano, uma vez que, a partir do deferimento do processo, o cliente será considerado um cidadão europeu, podendo residir e trabalhar em qualquer um dos países da União Européia sem a necessidade de um visto."
    },
    {
        question: "Não tenho os documentos dos meus ascendentes portugueses. Mesmo assim é possível obter a nacionalidade portuguesa?",
        answer: "De acordo com a legislação portuguesa, o documento primordial para a análise da atribuição da nacionalidade é a certidão de nascimento do português. Se, por algum motivo, o descendente não possuir a certidão, nossa equipe possui os meios necessários para auxiliá-lo na busca do documento."
    },
    {
        question: "É possível fazer faculdade em Portugal utilizando a nota do ENEM?",
        answer: "Diversas faculdades possuem acordo com o INEP (Instituto Nacional de Estudos e Pesquisas Educacionais) e permitem o acesso às Faculdades portuguesas por meio dos resultados obtidos pelo ENEM (Exame Nacional do Ensino Médio). Sendo um cidadão luso-brasileiro, o valor das mensalidades será reduzido, uma vez que as Universidades aplicam um valor diferenciado para os cidadãos europeus."
    },
    {
        question: "O que é o Vista-Tech?",
        answer: "O Visa-Tech é um programa do governo português que visa atrair profissionais altamente qualificados da área da tecnologia da informação – TI – que não possuem uma nacionalidade europeia, atraindo esses especialistas para o mercado de trabalho português."
    }
]