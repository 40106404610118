import React from 'react';

import './inputs.css';

export function TextArea({ id, type, placeholder, label, value, onChange }){
    return (
        <div className="form-input">
            <label
                className="form-input__label"
                htmlFor={id}
            >{label}</label>
            <textarea
                className="form-input__textarea"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            ></textarea>
        </div>
    )
}

export function TextInput({ id, type, placeholder, label, value, onChange }){

    return (
        <div className="form-input">
            <label
                className="form-input__label"
                htmlFor={id}
            >{label}</label>
            <input
                className="form-input__field"
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}