import React from 'react';

import './home-section.css';

function HomeSection({ showModal }) {
    return (
        <section className="home-section" id="home-section">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        <h2 className="home-section__headline">Nacionalidade Portuguesa</h2>
                        <p className="home-section__text">Tudo sobre como adquirir sua nacionalidade portuguesa</p>
                        <button className="home-section__cta" onClick={() => showModal(1)}>Quero saber mais</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeSection;