import React from 'react';

import './about-section.css';

import image from './images/about-section__image.jpg';

function AboutSection() {
    return (
        <section className="about-section" id="about-section">
            <div className="container">
                <div className="row row-centered">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        <h2 className="about-section__title">Quem somos</h2>
                        <p className="about-section__text">Equipe de advogados com atuação no direito internacional, inscritos na Ordem dos Advogados de Portugal e Ordem dos Advogados do Brasil, cujo foco principal consiste na prestação de serviços de assessoria jurídica em ambos os países para aqueles que pretendem o reconhecimento da nacionalidade portuguesa.</p>
                        <p className="about-section__text">Em razão da inscrição na Ordem Portuguesa, possuímos capacidade para representar o interesse de nossos clientes não somente em processos que visam o reconhecimento da nacionalidade portuguesa, mas também na homologação de sentenças estrangeiras (como decisões de divórcio e adoção, por exemplo).</p>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-12 offset-lg-1 col-lg-6 offset-xl-1 col-xl-6">
                        <img src={image} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;