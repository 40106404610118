import React, { Component } from 'react';

import MainHeader from '../../components/index-page/main-header';
import HomeSection from '../../components/index-page/home-section';
import AboutSection from '../../components/index-page/about-section';
import ServicesSection from '../../components/index-page/services-section';
import InfoSection from '../../components/index-page/info-section';
import FAQSection from '../../components/index-page/faq-section';
import ContactSection from '../../components/index-page/contact-section';
import Footer from '../../components/index-page/footer';

import Modal from '../../components/common/modal';

export default class IndexPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            theme: 'dark',
            modalIsVisible: false,
            modalContentId: null,
            contactIsVisible: false
        }
    }

    componentDidMount(){
        window.addEventListener("scroll", this.handleTheme);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.handleTheme)
    }

    componentDidUpdate(prevProps, prevState){
        if( prevState.contactIsVisible !== this.state.contactIsVisible || prevState.modalIsVisible !== this.state.modalIsVisible ){
            if( this.state.contactIsVisible || this.state.modalIsVisible ){
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        }
    }

    handleTheme = event => {
        const { theme } = this.state;
        if( window.scrollY >= window.innerHeight ) {
            if ( theme !== 'light' ){
                this.setState({ theme: 'light' });
            }
        } 
        if( window.scrollY < window.innerHeight ) {
            if ( theme !== 'dark' ){
                this.setState({ theme: 'dark' });
            }
        }
    }

    toggleContactVisibility = () => {
        this.setState({ contactIsVisible: !this.state.contactIsVisible })
    }

    showModal = id => {
        this.setState({  modalIsVisible: true, modalContentId: id });
    }

    hideModal = () => {
        this.setState({ modalIsVisible: false, modalContentId: null })
    }

    render(){
        const { theme, modalContentId , modalIsVisible, contactIsVisible } = this.state;

        return (
            <div className={`main-page ${contactIsVisible || modalIsVisible ? 'no-scroll':''}`}>
                <MainHeader theme={theme} />
                <HomeSection showModal={this.showModal} />
                <AboutSection />
                <ServicesSection showModal={this.showModal} />
                <InfoSection />
                <FAQSection />
                <Footer />
                <ContactSection theme={theme} isVisible={contactIsVisible} toggleContactVisibility={this.toggleContactVisibility} />
                { modalIsVisible && <Modal data_id={modalContentId} hideModal={this.hideModal} /> }
            </div>
        )
    }
}