import React from 'react';

import './info-section.css';

import imgNews1 from './images/news__001.jpg';
import imgNews2 from './images/news__002.jpg';

function InfoSection(){
    return (
        <section className="info-section" id="info-section">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h2 className="info-section__title">Informativos</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <h3 className="info-section__content-block__title">Últimas notícias</h3>
                        <div className="row">
                            <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <a href="https://hrportugal.sapo.pt/estas-sao-as-melhores-universidades-do-mundo-e-ha-portuguesas/" target="_blank" rel="noopener noreferrer">
                                    <div className="info-section__content-block__news">
                                        <div className="row">
                                            <div className="col col-12 order-1 col-sm-12 order-sm-1 col-md-6 order-md-2 col-lg-6 order-lg-2 col-xl-6 order-xl-2">
                                                <h4 className="info-section__content-block__news__title">Estas são as melhores universidades do mundo. E há portuguesas</h4>
                                                <p className="info-section__content-block__news__text">Em 150 instituições, os Estados Unidos são os mais bem posicionados num ranking da elite mundial do ensino liderado, este ano, pela...</p>
                                            </div>
                                            <div className="col col-12 order-2 col-sm-12 order-sm-2 col-md-6 order-md-1 col-lg-6 order-lg-1 col-xl-6 order-xl-1">
                                                <img src={imgNews1} alt="Trabalhadores dos registos e notariado começam nova greve" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">        
                                <a href="https://oglobo.globo.com/economia/portugal-busca-atrair-profissionais-de-ti-com-visto-especial-criado-este-ano-saiba-como-funciona-23930572" target="_blank" rel="noopener noreferrer">
                                    <div className="info-section__content-block__news">
                                        <div className="row">
                                            <div className="col col-12 order-1 col-sm-12 order-sm-1 col-md-6 order-md-2 col-lg-6 order-lg-2 col-xl-6 order-xl-2">
                                                <h4 className="info-section__content-block__news__title">Portugal busca atrair profissionais de TI com visto especial criado este ano. Saiba como funciona</h4>
                                                <p className="info-section__content-block__news__text">Brasileiros ficaram com 284 dos 338 Tech Visas emitidos até julho. Processo é mais rápido do que caminho...</p>
                                            </div>
                                            <div className="col col-12 order-2 col-sm-12 order-sm-2 col-md-6 order-md-1 col-lg-6 order-lg-1 col-xl-6 order-xl-1">
                                                <img src={imgNews2} alt="Portugal busca atrair profissionais de TI com visto especial criado este ano. Saiba como funciona" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                    {/* <div className="col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h3 className="info-section__content-block__title">Legislação</h3>
                        <div className="info-section__content-block__downloads">
                            <h4 className="info-section__content-block__downloads__title">Nacionalidade Portuguesa</h4>
                            <ul className="info-section__content-block__downloads__list">
                                <li className="info-section__content-block__downloads__list-item">
                                    <span className="info-section__content-block__downloads__list-item__name">Lei da Nacionalidade Portuguesa</span>
                                    <a href="#" className="button secondary-button">Ler</a>
                                </li>
                                <li className="info-section__content-block__downloads__list-item">
                                    <span className="info-section__content-block__downloads__list-item__name">Regulamento da Nacionalidade Portuguesa</span>
                                    <a href="#" className="button secondary-button">Ler</a>
                                </li>
                                <li className="info-section__content-block__downloads__list-item">
                                    <span className="info-section__content-block__downloads__list-item__name">Declaração de Bolonha</span>
                                    <a href="#" className="button secondary-button">Ler</a>
                                </li>
                            </ul>
                            <h4 className="info-section__content-block__downloads__title">Homologação de diplomas</h4>
                            <ul className="info-section__content-block__downloads__list">
                                <li className="info-section__content-block__downloads__list-item">
                                    <span className="info-section__content-block__downloads__list-item__name">Tratado de amizade e Cooperação entre Brasil e Portugal</span>
                                    <a href="#" className="button secondary-button">Ler</a>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default InfoSection;