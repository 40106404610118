import React from 'react';

import './styles.css';

export function Loading(){
    return (
        <div className="loading-icon"></div>
    )
}

export function ErrorMessage(){
    return (
        <div className="anim-fade-in">
            <div className="error-icon">
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
            </div>
            <h3 className="return-message error-message">Ocorreu um erro ao enviar o email</h3>
        </div>
    )
}

export function SuccessMessage(){
    return (
        <div className="anim-fade-in">
            <div className="success-icon">
                <div className="icon-bar"></div>
                <div className="icon-bar"></div>
            </div>
            <h3 className="return-message success-message">Mensagem enviada com sucesso!</h3>
        </div>
    )
}