import React from 'react';
import './style.css';

export function ChatIcon({ theme }){
    const primaryColor = theme === 'dark' ? '#000000' : '#ffffff';
    const secondaryColor = theme === 'dark' ? '#ffffff' : '#000000';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.192" viewBox="0 0 24 21.192">
            <g id="icon-chat" transform="translate(-1296.556 -693.074)">
                <path id="baloon" d="M15.759,17.146H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H22a2,2,0,0,1,2,2V15.145a2,2,0,0,1-2,2H20.016l1.635,4.046Z" transform="translate(1296.556 693.074)" fill={primaryColor}/>
                <circle id="elipse" cx="1" cy="1" r="1" transform="translate(1301.808 701.231)" fill={secondaryColor}/>
                <circle id="elipe" cx="1" cy="1" r="1" transform="translate(1307.116 701.231)" fill={secondaryColor}/>
                <circle id="elipse-2" data-name="elipse" cx="1" cy="1" r="1" transform="translate(1312.423 701.231)" fill={secondaryColor}/>
            </g>
        </svg>
    )
}

export function CrossIcon({ theme }){
    const primaryColor = theme === 'dark' ? '#000000' : '#ffffff';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.314" height="13.314" viewBox="0 0 13.314 13.314">
            <path fill={primaryColor} id="icon_cross" d="M-1250.393-.565l-3.536-3.535-3.536,3.535a1,1,0,0,1-1.414,0l-1.414-1.414a1,1,0,0,1,0-1.414l3.535-3.536-3.535-3.535a1,1,0,0,1,0-1.415l1.414-1.414a1,1,0,0,1,1.414,0l3.536,3.536,3.536-3.536a1,1,0,0,1,1.414,0l1.415,1.415a1,1,0,0,1,0,1.414l-3.536,3.535,3.536,3.536a1,1,0,0,1,0,1.414l-1.415,1.414a1,1,0,0,1-.707.293A1,1,0,0,1-1250.393-.565Z" transform="translate(1260.585 13.586)"/>
        </svg>
    )
}

export function FacebookLogo({ color }) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28">
            <path fill={color} d="M14.984 0.187v4.125h-2.453c-1.922 0-2.281 0.922-2.281 2.25v2.953h4.578l-0.609 4.625h-3.969v11.859h-4.781v-11.859h-3.984v-4.625h3.984v-3.406c0-3.953 2.422-6.109 5.953-6.109 1.687 0 3.141 0.125 3.563 0.187z"></path>
        </svg>
    )
}

export function InstagramLogo({ color }){
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 28">
            <path fill={color} d="M16 14c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM18.156 14c0 3.406-2.75 6.156-6.156 6.156s-6.156-2.75-6.156-6.156 2.75-6.156 6.156-6.156 6.156 2.75 6.156 6.156zM19.844 7.594c0 0.797-0.641 1.437-1.437 1.437s-1.437-0.641-1.437-1.437 0.641-1.437 1.437-1.437 1.437 0.641 1.437 1.437zM12 4.156c-1.75 0-5.5-0.141-7.078 0.484-0.547 0.219-0.953 0.484-1.375 0.906s-0.688 0.828-0.906 1.375c-0.625 1.578-0.484 5.328-0.484 7.078s-0.141 5.5 0.484 7.078c0.219 0.547 0.484 0.953 0.906 1.375s0.828 0.688 1.375 0.906c1.578 0.625 5.328 0.484 7.078 0.484s5.5 0.141 7.078-0.484c0.547-0.219 0.953-0.484 1.375-0.906s0.688-0.828 0.906-1.375c0.625-1.578 0.484-5.328 0.484-7.078s0.141-5.5-0.484-7.078c-0.219-0.547-0.484-0.953-0.906-1.375s-0.828-0.688-1.375-0.906c-1.578-0.625-5.328-0.484-7.078-0.484zM24 14c0 1.656 0.016 3.297-0.078 4.953-0.094 1.922-0.531 3.625-1.937 5.031s-3.109 1.844-5.031 1.937c-1.656 0.094-3.297 0.078-4.953 0.078s-3.297 0.016-4.953-0.078c-1.922-0.094-3.625-0.531-5.031-1.937s-1.844-3.109-1.937-5.031c-0.094-1.656-0.078-3.297-0.078-4.953s-0.016-3.297 0.078-4.953c0.094-1.922 0.531-3.625 1.937-5.031s3.109-1.844 5.031-1.937c1.656-0.094 3.297-0.078 4.953-0.078s3.297-0.016 4.953 0.078c1.922 0.094 3.625 0.531 5.031 1.937s1.844 3.109 1.937 5.031c0.094 1.656 0.078 3.297 0.078 4.953z"></path>
        </svg>
    )
}

export function FantasticaIcon({ theme }){
    const primaryColor = theme === 'dark' ? '#ffffff' : '#777777';

    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" version="1.1" y="0px" x="0px" viewBox="-1.12 -1.5 452 154" >
            <polygon fill={primaryColor} points="68.7 78.9 78.1 70.3 114 70.3 114 79.3 77.7 79.3 77.7 89.9 114 89.9 114 99 78.1 99 78.1 118 68.7 118"></polygon>
            <path fill={primaryColor} d="m123 79.2l9.18-9.18h28.6l8.96 8.96v39.2h-9.46v-19.8h-28v20h-9.25v-38.8zm9 10.4h28.2v-10h-28.2v10z"></path>
            <polygon fill={primaryColor} points="180 118 217 118 226 110 226 80 217 70.8 189 70.8 189 51.4 180 51.4 180 79.9 216 79.9 216 110 180 110"></polygon>
            <polygon fill={primaryColor} points="237 118 246 118 246 79.6 283 79.6 283 70.8 246 70.8 237 79.5"></polygon>
            <polygon fill={primaryColor} points="289 70.8 335 70.8 335 79.4 316 79.4 316 109 335 109 335 118 289 118 289 110 306 110 306 79.5 289 79.5"></polygon>
            <polygon fill={primaryColor} points="345 78.9 353 70.6 390 70.6 390 79.6 354 79.6 354 109 390 109 390 118 354 118 345 110"></polygon>
            <path fill="#00ccff" stroke="#00aeef" strokeMiterlimit="10" d="m124 25.4l-13.5-13.4-11.6 12.6c-5.82-3.98-12.5-6.82-19.7-8.19v-15.9h-19v15.8c-7.24 1.27-14 4.02-19.8 7.93l-10.8-10.9-13.5 13.4 10.6 10.7c-4.67 6.5-7.89 14.1-9.19 22.4h-16.5v19h17c1.5 7.24 4.49 13.9 8.65 19.7l-11 11.1 13.4 13 11.2-11.3c6.22 4.15 13.4 7.01 21.1 8.19v-24.9c-11.3-3.68-19.5-14.3-19.5-26.9 0-15.6 12.6-28.2 28.2-28.2 13.6 0 25 9.65 27.7 22.5h23.8c-0.94-9.05-4.18-17.4-9.13-24.5l12-12.6z"></path>
            <path fill={primaryColor} d="m399 79.6l9.18-9.18h28.6l8.96 8.96v39.2h-9.46v-20.4h-28v20h-9.25v-39.4zm10 10.4h28.2v-10h-28.2v10z"></path>
            <polygon fill={primaryColor} points="201 62.4 201 52.5 215 52.5 215 54.4 204 54.4 204 56.6 210 56.6 210 58.8 204 58.8 204 62.4"></polygon>
            <path fill={primaryColor} d="m216 62.4l8.46-9.92h3.58l8.04 9.92h-4.12l-2.33-2.96h-7.12l-2.29 2.96h-4.42m9-8.4l-2.73 3.44h5.47l-2-3.4z"></path>
            <path fill={primaryColor} d="m281 62.4l8.46-9.92h3.58l8.04 9.92h-4.12l-2.33-2.96h-7.12l-2.29 2.96h-4.42m9-8.4l-2.73 3.44h5.47l-2-3.4z"></path>
            <path fill={primaryColor} d="m377 62.4l8.46-9.92h3.58l8.04 9.92h-4.12l-2.33-2.96h-7.12l-2.29 2.96h-4.42m9-8.4l-2.73 3.44h5.47l-2-3.4z"></path>
            <polygon fill={primaryColor} points="242 62.4 245 62.4 245 55.5 256 62.4 259 62.4 259 52.5 256 52.5 256 59.4 245 52.5 242 52.5"></polygon>
            <polygon fill={primaryColor} points="263 52.5 280 52.5 280 54.5 273 54.5 273 62.4 270 62.4 270 54.5 263 54.5"></polygon>
            <polygon fill={primaryColor} points="203 133 225 133 225 135 216 135 216 146 212 146 212 135 203 135"></polygon>
            <rect fill={primaryColor} y="133" x="192" height="13.1" width="4.42"></rect>
            <polygon fill={primaryColor} points="231 146 251 146 251 143 235 143 235 141 244 141 244 138 235 138 235 136 251 136 251 132 231 132"></polygon>
            <polygon fill={primaryColor} points="118 146 138 146 138 143 122 143 122 141 131 141 131 138 122 138 122 136 138 136 138 132 118 132"></polygon>
            <polygon fill={primaryColor} points="326 52.5 343 52.5 343 54.5 336 54.5 336 62.4 333 62.4 333 54.5 326 54.5"></polygon>
            <path fill={primaryColor} d="m167 138h15.9s2.08 0.34 2.08 2.14 0 3.23 0 3.23 0.033 2.21-2.15 2.21h-18.9s-2.02-0.103-2.02-2.08v-1.12h3.01v1.23h15.3v-3.51h-16.3s-2.02 0-2.02-2.08v-3.3s-0.198-2.14 2.08-2.14h19s2.08-0.034 2.06 2.13v1.11h-2.89v-1.16h-15v4z"></path>
            <path fill={primaryColor} d="m263 138h15.9s2.08 0.34 2.08 2.14 0 3.23 0 3.23 0.033 2.21-2.15 2.21h-18.9s-2.02-0.103-2.02-2.08v-1.12h3.01v1.23h15.3v-3.51h-16.3s-2.02 0-2.02-2.08v-3.3s-0.197-2.14 2.08-2.14h19s2.08-0.034 2.06 2.13v1.11h-2.89v-1.16h-15.1v4z"></path>
            <path fill={primaryColor} d="m308 56.5h11.8s1.55 0.253 1.55 1.59v2.4s0.024 1.64-1.6 1.64h-14.1s-1.5-0.076-1.5-1.54v-0.835h2.24v0.911h11.3v-2.61h-12.1s-1.5 0-1.5-1.54v-2.46s-0.146-1.59 1.55-1.59h14.2s1.55-0.025 1.54 1.58v0.823h-2.15v-0.86h-11.2l-2 2.5z"></path>
            <path fill={primaryColor} d="m370 54.5h-9.92v5.83h9.92v-1h2.79v1s0.293 2.04-1.79 2.04h-12s-1.96 0.167-1.96-1.88v-6s0.001-2.04 1.96-2.04h11.9s1.89 0.042 1.89 2v1h-2.8v-0.9z"></path>
            <rect fill={primaryColor} y="52.5" x="347" height="9.92" width="3"></rect>
            <path fill={primaryColor} d="m85.9 132h17s9.61 0.622 9.61 6.6-9.68 6.54-9.68 6.54h-17.1v-14zm16.1 11c4.29 0 4.36-2.21 4.36-2.21v-3.6c0-2.77-4.36-2.97-4.36-2.97h-11.7v8.78c0-1 7.9-1 11.7-1z"></path>
        </svg>
    )
}

export function HamburgerIcon({ isMenuOpen, onClick, theme }){
    return (
        <div className={`button--hamburger ${isMenuOpen ? 'menu-is-open' : ''} ${theme}-theme`} onClick={onClick}>
            <div className="button--hamburger__icon">
                <div className="button--hamburger__icon-bar"></div>
                <div className="button--hamburger__icon-bar"></div>
                <div className="button--hamburger__icon-bar"></div>
            </div>
        </div>
    )
}

export function ReturnIcon({ color }){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.999" height="12" viewBox="0 0 29.999 12">
            <path fill={color || '#000000'} d="M33,11.016v1.969H6.844l3.563,3.609L9,18,3,12,9,6l1.406,1.406L6.844,11.015H33Z" transform="translate(-3.001 -6)" />
        </svg>
    )
}